export async function resetPassword(email, token, password, lang) {
  let error = null;

  try {
    const response = await fetch(
      `${process.env.GATSBY_AUTH_URL}/password/reset`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          token: token,
          password: String(password),
          lang: lang,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return await response.json();
  } catch (e) {
    error = e;
  }

  return { error };
}
