import React, { useState, useEffect } from "react";
import Bugsnag from "@bugsnag/js";
import { connect } from "react-redux";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import navigate from "~/components/navigate";
import Layout from "~/components/layout";
import SEO from "~/components/seo";
import { useForm } from "react-hook-form";
import Input from "~/components/input";
import InputError from "~/components/inputError";
import { getParameterByName } from "~/utils/app-helpers";
import ButtonStateful from "~/components/buttonStateful";
import { resetPassword } from "~/services/auth/resetPass";
import { newPasswordValidator } from "~/components/passwordValidator";
import SubmitSuccess from "~/components/auth/submitSuccess";

function ResetPage(props) {
  const paths = props.pageContext.paths;
  const i18n = props.i18n;
  const lang = i18n.language;

  const email = getParameterByName("email");
  const token = getParameterByName("token");
  const {
    register,
    handleSubmit,
    getValues,

    formState: {
      errors,
    },
  } = useForm({
    mode: "onBlur",
  });

  const [error, setError] = useState(null);
  const [disableForm, setDisableForm] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);

  const [logged, setLoginStatus] = useState(null);

  useEffect(() => {
    if (props.auth.email) {
      navigate("/", i18n.language);
      return () => {
        return false;
      };
    } else setLoginStatus(false);

    return () => {
      return false;
    };
  }, [props.auth.email]);

  const handleFormReset = async (body) => {
    setError(null);
    setDisableForm(true);

    try {
      const { error, success, data } = await resetPassword(email, token, body.password, lang);

      if (error) {
        throw new Error(error.message);
      }

      if (success) {
        setResetSuccess(true);

        setTimeout(() => {
          navigate(`/login/?msg=pass_updated`, lang);
          return false;
        }, 2000);
      } else {
        let msg = "";

        if (data.code === 1) {
          msg = "token_not_found";
        }

        if (data.code === 2) {
          msg = "invalid_token";
        }

        if (data.code === 3) {
          msg = "token_expired";
        }

        navigate(`/forgot/?err=${msg}`, lang);
        return false;
      }
    } catch (e) {
      Bugsnag.notify(e);
      setError(e.message);
    }

    setDisableForm(false);
  };

  if (logged === null) {
    return null;
  }

  return (
    <Layout paths={paths} hideFooter={true} className="h-screen">
      <SEO title={i18n._(t`Set a new password`)} location={paths} />

      <div className="flex  md:items-center md:margin-auto justify-center h-mobile md:h-desktop">
        <div className="px-4 pt-8 md:pt-0 md:min-w-1/2 lg:min-w-1/3  xl:min-w-1/4">
          <div className="text-center w-full">
            {resetSuccess ? (
              <div className="h-48 flex items-center justify-center">
                <div className="text-center">
                  <SubmitSuccess>
                    <Trans>Great!, redirecting to login page... please wait...</Trans>
                  </SubmitSuccess>
                </div>
              </div>
            ) : (
              <>
                <h2 className="mb-1">
                  <Trans>Set up a new password</Trans>
                </h2>
                <div className="text-sm my-3 mb-8">
                  <Trans>In order to protect your account ensure your new password:</Trans>
                  <ul className="list-disc text-left ml-12 mt-4 text-base">
                    <li>
                      <Trans>Is longer than 7 characters</Trans>
                    </li>
                    <li>
                      <Trans>has at least 1 number</Trans>
                    </li>
                    <li>
                      <Trans>has at least 1 uppercase letter</Trans>
                    </li>
                    <li>
                      <Trans>has at least 1 lowercase letter</Trans>
                    </li>
                  </ul>
                </div>
                {error !== null && <div className="bg-red-200 border border-red-200 text-red-600 rounded-brand mb-4 p-2 text-base">{error}</div>}
                <form className="text-sm text-left">
                  <div className="mb-3 text-base">
                    <Trans>Your email to login</Trans>: <span className="font-semibold">{email}</span>
                  </div>
                  <input type="text" value={email} autoComplete="username" className="hidden" readOnly />
                  <div className="mb-3 md:mb-4">
                    <Input
                      type="showHide"
                      placeholder={i18n._(t`New password`)}
                      {...register('password', {
                        required: true,
                        minLength: {
                          value: 8,
                          message: i18n._(t`The min value is 8`),
                        },
                        validate: newPasswordValidator,
                      })}
                      autoComplete="new-password"
                      error={typeof errors["password"] !== "undefined"}
                      required
                      disabled={disableForm}
                      tabIndex={1} />
                    <InputError errors={errors} field="password" />
                  </div>
                  <div className="mb-3 md:mb-4">
                    <Input
                      type="showHide"
                      placeholder={i18n._(t`Re-enter your new password`)}
                      {...register('confirmPassword', {
                        validate: (value) => value === getValues().password || i18n._(t`Passwords do not match`),
                      })}
                      autoComplete="new-password"
                      error={typeof errors["confirmPassword"] !== "undefined"}
                      required
                      disabled={disableForm}
                      tabIndex={2} />
                    <InputError errors={errors} field="confirmPassword" />
                  </div>
                  <div className="mb-3 md:mb-4">
                    <ButtonStateful type="submit" onClick={handleSubmit(handleFormReset)} loading={disableForm} tabIndex={3} className="h-12 text-lg">
                      <Trans>Change password</Trans>
                    </ButtonStateful>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

export default withI18n()(connect(mapStateToProps)(ResetPage));
